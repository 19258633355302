function getBasePathByRole() {
  if (!window.loggedUser) {
    throw Error('Missing window.loggedUser');
  }
  if (window.loggedUser.type === 'ADMIN') {
    return '/admin';
  } else if (window.loggedUser.type === 'SUPERADMIN') {
    return '/superadmin';
  } else if (window.loggedUser.type === 'CUSTOMER_ADMIN') {
    return '/customersadmin';
  } else {
    throw Error('Unsupported user type: ' + window.loggedUser.type);
  }
}

$(document).ready(function () {
  var language = Cookies.get('i18n');
  var selectedLanguageLink;

  if (language === 'it') {
    selectedLanguageLink = $('#itaLang')
  } else {
    selectedLanguageLink = $('#engLang')
  }

  selectedLanguageLink.toggleClass('selectedLanguage')
});
